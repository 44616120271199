@import '../vendors/bootstrap/functions';
@import '../vendors/bootstrap/variables';
@import '../variables';
@import '../vendors/bootstrap/mixins';

.cms-noroute-index {

  .page-wrapper {
    background: $drop-down-gray;

    .page-main {
      max-width: 100%;
    }

    .page-not-found-content {
      display: flex;
      flex-direction: row;
      padding: 6.9rem 0;
      max-width: 1062px;
      width: 100%;
      justify-content: space-between;
      margin: 0 auto;

      .text-content {

        .title {
          font-size: 6.8rem;
          font-weight: 700;
          font-family: $headings-font-family;
          color: $primary;
          text-transform: uppercase;
          text-shadow: -2px -2px 0 $primary, 2px -2px 0 $primary, -2px 2px 0 $primary, 2px 2px 0 $primary;
          color: $drop-down-gray;
          line-height: 1.1;
          letter-spacing: 1px;
        }

        .subtitle {
          font-size: 3.6rem;
          color: $primary;
          font-weight: 500;
          font-family: $headings-font-family;
          text-transform: uppercase;
          margin-bottom: 1rem;
          margin-top: -1rem;
        }

        .text {
          font-size: 1.8rem;
          color: $noroute-text-gray;
          line-height: 2.6rem;
          margin-bottom: 3rem;
        }

        .go-back {
          color: $gray-800;
          font-size: 1.5rem;
          text-transform: uppercase;
          font-family: $headings-font-family;
          font-weight: 500;
          padding: 0.9rem 5.3rem;
          border: 1px solid $gray-800;
          background: $white;
          line-height: 1;
          box-shadow: $btn-box-shadow-bottom;
          text-decoration: none;
          border-radius: 2px;

          &:hover,
          &:active {
            color: $white;
            background: $gray-800;
          }
        }
      }

      @include media-breakpoint-down(lg) {

        .image-content {
          display: none;
        }
      }

      @include media-breakpoint-down(xs) {
        justify-content: center;
        flex-direction: column;

        .text-content {
          text-align: center;

          .title {
            font-size: 4.5rem;
            text-shadow: -1px -1px 0 $primary, 1px -1px 0 $primary, -1px 1px 0 $primary, 1px 1px 0 $primary;
            margin-bottom: 1rem;
          }

          .subtitle {
            font-size: 2.4rem;
          }

          .text {
            font-size: 1.4rem;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
